<script setup lang="ts">
import { initFlowbite } from 'flowbite';
import { useStoryblokBridge, type StoryblokComponentType } from '@storyblok/vue';

const { params } = useRoute();
const { slug } = params;
const { locale } = useI18n();

const url = slug && Array.isArray(slug) && slug.length > 0 ? slug.join('/') : 'home';

const story = await useAsyncStoryblok(url.replace(/\/$/, ''), {
    version: useRoute().query._storyblok ? 'draft' : 'published',
    language: locale.value,
});

if (story.value.status) {
    throw createError({
        statusCode: story.value.status,
        statusMessage: story.value.response,
    });
}

const { meta } = await useDefaultMetatags(story, locale);

onMounted(() => {
    initFlowbite();
    if (story.value && story.value.id) {
        useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory));
    }
});

provide('story', story);

useServerSeoMeta(meta);

const languages = ['en', 'sv', 'de', 'pt'];
const markets: Record<string, string> = {
    uk: 'gb',
    de: 'de',
    pt: 'pt',
    se: 'se',
    at: 'at',
};

const route = useRoute();
const currentPath = computed(() => {
    // Remove language prefix if present
    const path = route.path;
    for (const lang of languages) {
        if (path.startsWith(`/${lang}/`)) {
            return path.substring(lang.length + 1);
        }
    }

    return path.startsWith('/') ? path.substring(1) : path;
});

const joinPaths = (...parts: string[]) => {
    return parts
        .map((part) => part.trim().replace(/^\/|\/$/g, ''))
        .filter((part) => part !== '')
        .join('/');
};

// Generate custom hreflang links
const generateHreflangLinks = () => {
    const links: Array<{ rel: string; href: string; hreflang: string }> = [];
    const baseUrl = 'https://tiptapp.com';

    // Special case: map root path to "home"
    const path = currentPath.value === '' ? 'home' : currentPath.value;

    // Check if visibleIn property exists and has values
    const visibleIn: string[] = story.value?.content?.visibleIn || [];

    const hasVisibilityRestrictions = Array.isArray(visibleIn) && visibleIn.length > 0;

    // Helper function to check if a market should be included
    const shouldIncludeMarket = (marketKey: string): boolean => {
        if (!hasVisibilityRestrictions) return true;
        return visibleIn.includes(marketKey);
    };

    // Helper function to check if a language should be included
    const shouldIncludeLanguage = (lang: string): boolean => {
        if (!hasVisibilityRestrictions) return true;

        // Map languages to their respective market codes
        const marketCodesForLanguage: Record<string, string[]> = {
            en: ['gb', 'uk'],
            sv: ['se'],
            de: ['de', 'at'],
            pt: ['pt'],
        };

        // Check if any market code for this language is in visibleIn
        return marketCodesForLanguage[lang]?.some((code) => visibleIn.includes(code)) || false;
    };

    // Add default English (x-default) - only if English is included
    if (shouldIncludeLanguage('en')) {
        links.push({
            rel: 'alternate',
            href: path === 'home' ? baseUrl : `${baseUrl}/${joinPaths(path)}`,
            hreflang: 'x-default',
        });

        // English for all variations (default)
        links.push({
            rel: 'alternate',
            href: `${baseUrl}/${joinPaths('en', path)}`,
            hreflang: 'en',
        });
    }

    // English with each market
    Object.entries(markets).forEach(([marketKey, marketCode]) => {
        if (shouldIncludeMarket(marketKey)) {
            links.push({
                rel: 'alternate',
                href: `${baseUrl}/${joinPaths('en', path)}?market=${marketKey}`,
                hreflang: `en-${marketCode}`,
            });
        }
    });

    // Other languages (base version)
    ['sv', 'de', 'pt'].forEach((lang) => {
        if (shouldIncludeLanguage(lang)) {
            links.push({
                rel: 'alternate',
                href: `${baseUrl}/${joinPaths(lang, path)}`,
                hreflang: lang,
            });

            // Add market-specific versions only for matching markets
            const marketMatches: Record<string, string[]> = {
                sv: ['se'],
                de: ['de'],
                pt: ['pt'],
            };

            (marketMatches[lang] || []).forEach((marketKey) => {
                if (marketKey in markets && shouldIncludeMarket(marketKey)) {
                    links.push({
                        rel: 'alternate',
                        href: `${baseUrl}/${joinPaths(lang, path)}?market=${marketKey}`,
                        hreflang: `${lang}-${markets[marketKey as keyof typeof markets]}`,
                    });
                }
            });
        }
    });

    return links;
};

const hreflangLinks = computed(() => generateHreflangLinks());

useHead({
    link: [...hreflangLinks.value],
});
</script>

<template>
    <StoryblokComponent
        v-if="story"
        :blok="{
            ...story.content,
            published_at: story.published_at,
            created_at: story.created_at,
            first_published_at: story.first_published_at,
        }"
    />
</template>
